var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "국내사업",
      "tabActive": "지역공동체지원",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title', {
    attrs: {
      "tit": "지역공동체 지원",
      "sc": "복합적인 문제와 욕구를 가진 지역주민들에게 지역사회자원 개발 및 연계를 통해 문제 해결을 돕고, 역량을 강화하여 자립을 지원하며 지역사회의 문제와 욕구를 해결하기 위하여 지역주민들이 주체적으로 조직을 구성하여 활동하고 지역공동체를 형성하도록 원조."
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "src": "/images/sub/business/community/community-img.svg",
      "max-width": "872",
      "aspect-ratio": 872 / 460
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
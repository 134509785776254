<template>
    <client-page>

        <sub-visual
        sh="국내사업"
        tabActive="지역공동체지원"
        bg="/images/sub/visual/sv2.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <page-title
                tit="지역공동체 지원"
                sc="복합적인 문제와 욕구를 가진 지역주민들에게 지역사회자원 개발 및 연계를 통해 문제 해결을 돕고, 역량을 강화하여 자립을 지원하며 지역사회의 문제와 욕구를 해결하기 위하여 지역주민들이 주체적으로 조직을 구성하여 활동하고 지역공동체를 형성하도록 원조."/>
            
                <v-img src="/images/sub/business/community/community-img.svg" max-width="872" :aspect-ratio="872 / 460" class="w-100 mx-auto" />
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageTitle from "@/components/client/sub/page-title.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        PageTitle,
    },
};
</script>

<style lang="scss" scoped>
</style>